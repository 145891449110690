import React, { Component } from 'react';
import Contact from '../Contact/Contact';
import Profile from '../Profile/Profile';
import Projects from '../Projects/Projects';

export default class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='content'>
          <div className='content__section'>
            <Profile />
          </div>
          <div className='content__section'>
            <Projects />
          </div>
          <div className='content__section'>
            <Contact />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
