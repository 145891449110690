import React, { PureComponent } from 'react';

export default class Contact extends PureComponent {
  render() {
    return (
      <div className='contact'>
        <h2>Get in touch</h2>
        <div className='contact__content'>
          <p className='contact__email'>
            If you want to say hello, send me an email at&nbsp;
            <a href='mailto: emmalivingstone94@gmail.com'>
              emmalivingstone94@gmail.com
            </a>
            .
          </p>
          <p className='contact__socials'>
            You can also find me on&nbsp;
            <a
              href='https://github.com/emmalivingstone'
              className='social-link social-link--github'
              target='_blank'
              rel='noopener noreferrer'
            >
              Github
            </a>
            &nbsp;or&nbsp;
            <a
              href='https://www.linkedin.com/in/emmalivingstone94'
              className='social-link social-link--linkedin'
              target='_blank'
              rel='noopener noreferrer'
            >
              LinkedIn
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}
