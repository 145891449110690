import React from 'react';
import soundwaves from './Soundwaves-site.png';
import tyneToGo from './Tyne-to-go.png';
import reactStorybook from './React-storybook.png';

const Projects = () => {
  return (
    <div className='projects'>
      <h2>Things I've worked on</h2>
      <div className='projects__item'>
        <img
          className='projects__item-img'
          src={reactStorybook}
          alt='Storybook screenshot'
        />
        <a
          href='https://react-storybook9209.netlify.app/'
          target='__blank'
          className='projects__item-overlay'
        >
          <div className='projects__item-text'>
            <p className='projects__item-title'>React Design Storybook</p>
            <p className='projects__item-description'>
              A React/Storybook project I created to practice my React & design
              skills.
            </p>
          </div>
        </a>
      </div>

      <div className='projects__item'>
        <img
          className='projects__item-img'
          src={soundwaves}
          alt='Soundwaves screenshot'
        />
        <a
          href='https://soundwaves.emmalivingstone.xyz'
          target='__blank'
          className='projects__item-overlay'
        >
          <div className='projects__item-text'>
            <p className='projects__item-title'>Soundwaves</p>
            <p className='projects__item-description'>
              A web application built in React for checking out your top Spotify
              tracks and artists for different time periods.
            </p>
          </div>
        </a>
      </div>

      <div className='projects__item'>
        <img
          className='projects__item-img'
          src={tyneToGo}
          alt='Tyne to Go screenshot'
        />
        <a
          href='https://pensive-tesla-4bb8d8.netlify.app/'
          target='__blank'
          className='projects__item-overlay'
        >
          <div className='projects__item-text'>
            <p className='projects__item-title'>Tyne to Go</p>
            <p className='projects__item-description'>
              A (now archived) web application built in Angular for listing
              local businesses in Newcastle upon Tyne still delivering food
              during the Covid-19 pandemic.
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Projects;
